<template>
  <a-modal v-model="isVisible" title="Find a club to join" @cancel="close">
    <a-row type="flex" justify="space-between" :gutter="1">
      <a-col :xs="24" :sm="18" :md="17" style="margin-bottom: 10px">
        <a-input
          v-model="keyword"
          type="email"
          placeholder="Search for a club"
        ></a-input>
      </a-col>
      <a-col :xs="24" :sm="6" :md="5" style="margin-bottom: 10px">
        <a-button block type="primary" @click="searchClubs">Search</a-button>
      </a-col>
    </a-row>
    <div class="modal_searchdata_container">
      <a-row
        v-for="club of clubs"
        :key="club.id"
        type="flex"
        :gutter="4"
        class="modal_searchdata"
      >
        <a-col :span="24" :sm="12" :md="8">
          {{ club.club_name }}
        </a-col>
        <a-col :span="24" :sm="12" :md="8"> {{ club.address }}... </a-col>
        <a-col :span="24" :sm="12" :md="8" class="gx-text-right">
          <a-button
            v-if="
              club.members &&
              club.members.length &&
              (club.members[0].pivot.status === 'decline' ||
                club.members[0].pivot.status === 'archive')
            "
            block
            size="small"
            @click="requestToJoin(club.id)"
            class="ant-btn-block"
          >
            Request to Join
          </a-button>
          <a-button
            v-if="club.members && !club.members.length"
            type="danger"
            block
            size="small"
            @click="requestToJoin(club.id)"
            class="ant-btn-block"
          >
            <template>Request to Join</template>
          </a-button>

          <a-button
            v-if="
              club.members &&
              club.members.length &&
              (club.members[0].pivot.status === 'invite' ||
                club.members[0].pivot.status === 'accept' ||
                club.members[0].pivot.status === 'request')
            "
            type="danger"
            block
            size="small"
            class="ant-btn-block"
          >
            <template v-if="club.members[0].pivot.status === 'invite'"
              >Invitation Pending</template
            >
            <template v-if="club.members[0].pivot.status === 'request'"
              >Request Sent
            </template>
            <template v-if="club.members[0].pivot.status === 'accept'"
              >Already a Member</template
            >
          </a-button>
        </a-col>
      </a-row>
      <a-row v-if="clubs.length < 1 && error_msg"
        ><a-col :xs="18" class="gx-text-center"> No result found </a-col>
      </a-row>
    </div>
    <div slot="footer"></div>
  </a-modal>
</template>

<script>
import notifications from "@/common/notifications/notification.service";
import { clubService } from "@/common/api/api.service";

export default {
  name: "SearchClubsModal",
  props: {
    visible: {
      default: false,
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      keyword: "",
      clubs: [],
      error_msg: false,
    };
  },
  computed: {
    isVisible: {
      get() {
        return this.visible;
      },
      set() {
        return false;
      },
    },
  },
  methods: {
    requestToJoin(clubId) {
      clubService
        .requestToJoin({
          clubId: clubId,
        })
        .then((resp) => {
          if (resp.data.success) {
            this.searchClubs();
            notifications.success(
              "We have notified the club, you should hear back soon."
            );
          } else {
            notifications.warn("Something went wrong with this request");
          }
        });
    },
    searchClubs() {
      this.error_msg = false;
      clubService
        .searchClubs({
          keyword: this.keyword,
        })
        .then((resp) => {
          if (resp.data.success) {
            this.clubs = resp.data.result;
            this.error_msg = true;
          }
        });
    },
    close() {
      (this.clubs = []), (this.keyword = ""), this.form.resetFields();
      this.error_msg = false;
      this.$emit("close");
    },
  },
};
</script>
<style>
.search_member-popup .ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 6px 6px;
  display: none;
}
.modal_searchdata {
  margin-bottom: 15px;
  border: 1px solid #d9d9d9;
  padding: 5px;
  border-radius: 5px;
}
.modal_searchdata button.ant-btn-block.ant-btn.ant-btn-sm.ant-btn-block {
  margin: 5px 0;
}
.modal_searchdata_container {
  max-height: 50vh;
  overflow: auto;
  width: 100%;
}
</style>
